import { useNavigate } from 'react-router-dom';
import logo from './images/sunriselogo.jpg';
import axios from 'axios';
function Home() {
    let nav=useNavigate();
  function saveData()
  {
    let s1=document.getElementById("t1").value;
    let s2=document.getElementById("t2").value;
    if(s2.length!=10)
    {
      alert("Invalid Mobile No.");
      document.getElementById("t2").focus();
      return;
    }
    else
    {
        let obj={course:s1,mobile:s2};
        axios.post("https://query.sci.ind.in/students.php",JSON.stringify(obj)).then(reply=>{
            if(reply.status==200)
            {
                if(reply.data.status=="Ok")
                {
                    nav("/second");
                }
            }
        });        
    }
  }
  return (
    <div style={{margin:'0px'}}>    
        <div style={{width:'100%', maxWidth:'400px',height:'100%', maxHeight:'600px',borderStyle:'double',borderWidth:'3px', borderColor:'gray',padding:'10px',marginTop:'50px'}}>
          <center><img src={logo}/></center>
          <hr/>
          <div className="form-group">
            <label>In Which Course You Are Interested?</label>
            <select  id="t1" className='form-control'>
              <option>C Language</option>
              <option>C++ Language</option>
              <option>Core Java</option>
              <option>Adv. Java (Servlet,JSP,Hibernate)</option>
              <option>Java Springboot</option>
              <option>C#.NET</option>
              <option>ASP.NET MVC Core</option>
              <option>.NET MAUI</option>
              <option>Core Python</option>
              <option>AI,ML with Python</option>
              <option>DJango Framework</option>
              <option>React JS (UI)</option>
              <option>React Native (UI)</option>
              <option>Node JS with Express and Mongo</option>
              <option>HTML,CSS,JavaScript(UI)</option>
              <option>Oracle</option>
              <option>SQL Server</option>
              <option>Android Programming</option>
              <option>Other</option>
            </select>
          </div>
          <div className="form-group">
            <label>Mobile No.</label>
            <input type="number" id="t2" className='form-control'/>
          </div>
          <div className="form-group">
            <label>We will not share your Contact No with any other Organization/Search Engine. We will only send Whatsapp/SMS for New Course/Batch Details.</label>            
          </div>
          <div className="form-group">
            <input onClick={saveData} type="button" value="Submit" className='form-control btn-primary'/>
          </div>
        </div>            
    </div>
  );
}

export default Home;
