import { Route, Routes } from 'react-router-dom';
import Home from './home';
import SecondPage from './secondpage';
import WebPage from './webpage';
function App() {
  return (
    <div style={{margin:'0px'}}>    
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/second' element={<SecondPage/>}></Route>
          <Route path='/web' element={<WebPage/>}></Route>
        </Routes>          
    </div>
  );
}

export default App;
