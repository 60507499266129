import { useNavigate } from 'react-router-dom';
import logo from './images/sunriselogo.jpg';
export default function SecondPage()
{
    let nav=useNavigate();
    function f1()
    {
        window.open("https://query.sci.ind.in/Sunrise.pdf");
    }
    function f4()
    {
        window.open("https://query.sci.ind.in/Poster.pdf");
    }
    function f2()
    {
        window.open("https://wa.me/917830080808");
    }
    function f3()
    {
        window.open("https://sci.ind.in","_self");
    }
    return(
        <div style={{margin:'0px'}}>    
        <div style={{width:'100%', maxWidth:'400px',height:'100%', maxHeight:'600px',borderStyle:'double',borderWidth:'3px', borderColor:'gray',padding:'10px',marginTop:'50px'}}>
          <center><img src={logo}/></center>
          <hr/>          
          <div className="form-group">
            <input type="button" onClick={f1} value="Download Course Brochure" className='form-control btn-primary'/>
          </div>
          <div className="form-group">
            <input type="button" onClick={f4} value="Download Career Chart" className='form-control btn-primary'/>
          </div>
          <div className="form-group">
            <input type="button" onClick={f2} value="Connect on Whatsapp" className='form-control btn-primary'/>
          </div>
          <div className="form-group">
            <input type="button" onClick={f3} value="Continue" className='form-control btn-primary'/>
          </div>
        </div>            
    </div>
    );
}